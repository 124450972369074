/* @import url('https://fonts.googleapis.com/css2?family=Noto+Music&display=swap'); */

@font-face {
  font-family: "Bravura Text";
  src: url(/static/media/BravuraText.ff7bb15a.woff2) format("woff2"),
    url(/static/media/BravuraText.bc38baaf.woff) format("woff");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Bravura";
  src: url(/static/media/Bravura.5dba6315.woff2) format("woff2"),
    url(/static/media/Bravura.d7c2d2ce.woff) format("woff");
  font-weight: bold;
  font-display: swap;
}

/* @font-face {
  font-family: "Petaluma";
  src: url("./fonts/Petaluma.woff2") format("woff2"),
    url("./fonts/Petaluma.woff") format("woff");
  font-weight: bold;
  font-display: swap;
} */

/* @font-face {
  font-family: "Petaluma Script";
  src: url("./fonts/PetalumaScript.woff2") format("woff2"),
    url("./fonts/PetalumaScript.woff") format("woff");
  font-weight: bold;
  font-display: swap;
} */

/* * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Bravura Text";
  font-weight: 400;
} */

*,
*::before,
*::after {
  box-sizing: border-box;
}


body,
html {
  background-color: rgb(0, 0, 0);
  /* font-family: 'Noto Music'; */
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  outline: none;
  padding: 0;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Trebuchet MS', sans-serif;
  color: rgb(220, 220, 220);
  /* position: fixed;
  border: 0; */
}

/* body:not(.using-keyboard) button:focus, body:not(.using-keyboard) input:focus {
  outline: none;
} */



/* @media only screen and (max-width: 768px) { */

/* .pie-chord-symbol {
      font-size: 8vw; */
/* Adjust the size for mobile screens */

/* } */

a {
  font-family: 'Trebuchet MS', sans-serif;
  color: #fff;
  /* Set the text color */
  -webkit-text-decoration: bold;
          text-decoration: bold;
  /* Remove underline from links */
}

a:hover {
  text-decoration: underline;
  /* Add underline on hover for better user experience */
}


.userguide {
  font-family: 'Trebuchet MS', sans-serif;
  color: rgb(220, 220, 220);
  text-align: left;
}

.musicfont {
  /* font-family: 'Noto Music'; */
  font-family: 'Bravura Text';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

sup {
  font-size: 0.7em;
}

/* input:focus {
  outline: none;
  color: blue;
  border: 1px solid black;
  text-decoration: underline;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
} */


/* #piano {
  position: fixed; /* This makes the element positioned relative to the viewport */
/* bottom: 0; /* This positions the element at the bottom of the viewport */
/* width: 100vw; /* This makes the element as wide as the viewport */
/* height: 20vh; /* This makes the element's height 20% of the viewport's height */
/* } */

#piano {
  display: flex;
  justify-content: space-between;
  height: 100%;
  /* Adjust this value as needed */
  top: 39dvw;
  /* Adjust this value to match the new height of the #piano container */
  width: 99vw;
  position: relative;
  z-index: 1;
}

#color-key {
  display: flex;
  width: 15dvw;
  /* adjust this to change the size of the image */
  height: 100dvw;
  top: 5.8dvw;
  left: 0.5dvw;
  opacity: 0.8;
  /* top: 14.5dvw; /* centered in pie chart */
  /* left: 77.5dvw; /* centered in pie chart */
  position: absolute;
  z-index: 4;
}

.color-key-container {
  text-align: left;
  width: 13dvw;
  height: 20dvw;
  position: absolute;
  top: 2dvw;
  left: 0dvw;
  margin-bottom: 0.1dvw;
  /* Add a margin to create spacing between the note and the bottom */
  margin: 0.9dvw;
  padding: 0.1dvw;
}

#color-key .white {
  width: 1.91dvw;
  height: 10dvw;
  border: 0.5px solid rgb(120, 120, 120);
  border-bottom-right-radius: 0.25dvw 0.25dvw;
  border-bottom-left-radius: 0.25dvw 0.25dvw;
  /* border: 0.5px solid white;
  border-bottom-right-radius: 3px 3px;
  border-bottom-left-radius: 3px 3px;
  border-bottom-width: 3; */
  /* var(--activeColor); */
  /* background: rgb(90, 90, 90); */
  /* background: rgb(72, 72, 72); */
  /* border: 0.5px solid rgb(146, 146, 146); */
  /* position: relative; */
  /* ...existing styles... */
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: center; */
  /* justify-content: flex-end; */
}

#color-key .black {
  width: 1.2dvw;
  height: 6.8dvw;
  border-bottom-right-radius: 0.25dvw 0.25dvw;
  border-bottom-left-radius: 0.25dvw 0.25dvw;
  /* border: 1px solid white;
  border-bottom-right-radius: 3px 3px;
  border-bottom-left-radius: 3px 3px;
  border-bottom-width: 3; */
  /* var(--activeColor); */
  /* background: linear-gradient(180deg, black, rgb(32, 32, 32), black, rgb(0, 0, 0)); */
  /* border: 1px solid rgb(50, 50, 50); */
  /* position: absolute; */
  /* z-index: 2; */
  /* ...existing styles... */
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: center; */
  /* justify-content: flex-end; */
}

.ck-active {
  animation: pulse 0.3s forwards;
  transform-origin: 0dvw 0dvw;
  stroke: black;
  stroke-width: 0.4;
  stroke-opacity: 1;
  /* font-weight: 700; */
  opacity: 1;
  /* transition: fill 0.9s ease; */
  /* fill: #f00; /* Active color for the pie slices */
}

#color-key .ck-toggled {
  animation: pulse-key 0.4s forwards;
  transform-origin: 1dvw 4dvw;
  border: 0px inset rgb(10, 10, 10);
  border-bottom-right-radius: 0.25dvw 0.25dvw;
  border-bottom-left-radius: 0.25dvw 0.25dvw;
  border-top-right-radius: 0.15dvw 0.15dvw;
  border-top-left-radius: 0.15dvw 0.15dvw;
  /* border: 1px solid rgb(255, 255, 255); */
  /* stroke: black; */
  /* stroke-width: 0; */
  /* stroke-opacity: 1; */
  opacity: 1;
  /* transition: fill 0.9s ease; */
  /* fill: #f00; /* Active color for the pie slices */
}

#color-key .inactive-scale-note {
  border: 0.5px solid rgb(120, 120, 120);
  border-bottom-right-radius: 0.4dvw 0.4dvw;
  border-bottom-left-radius: 0.4dvw 0.4dvw;
  /* border-bottom-width: 3; */
  /* border-color: var(--activeColor); */
  /* color: rgb(110, 110, 110); */
}

.boxed {
  position: absolute;
  top: -0.2dvw;
  left: -0.3dvw;
  width: 1.7dvw;
  height: 1.7dvw;
  border: 1.3px solid rgb(110, 110, 110);
  border-radius: 0.4dvw;
}

:root {
  --flash-duration: 0.6s;
}

.key {
  flex-grow: 1;
  position: relative;
  height: 100%;
  border: 0.5px solid rgb(255, 255, 255);
  border-bottom-right-radius: 0.2dvw 0.2dvw;
  border-bottom-left-radius: 0.2dvw 0.2dvw;
}

.flash-overlay {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* Define the flash-overlay animation */
@keyframes flash-overlay {
  0% {
    background-color: #000;
    opacity: 1;
  }

  /* Start with a full black overlay */
  10% {
    background-color: #fff;
    opacity: 1;
  }

  /* Quickly switch to a full white overlay */
  100% {
    opacity: 0;
  }

  /* Fade to fully transparent, revealing the active color underneath */
}

/* Apply the flash-overlay animation to the flash-overlay class */
.flash-overlay.flash {
  animation: flash-overlay var(--flash-duration) ease-out forwards;
}


/* Define the flash-emo animation */
@keyframes flash-emo {
  0% {
    color: black;
  }

  10% {
    color: white;
  }

  100% {
    color: var(--activeColor);
  }
}

/* Apply the flash-emo animation to the flash-emo class */
.flash-emo {
  animation: flash-emo var(--flash-duration) ease-out forwards;
}

/* Define the flash-pc-label animation */
@keyframes flash-pc-label {

  /* 0% { fill: black; } */
  0% {
    fill: white;
  }

  /* 10% { fill: white; } */
  100% {
    fill: black;
  }
}

/* Apply the flash-pc-label animation to the flash-pc-label class */
.flash-pc-label {
  animation: flash-pc-label var(--flash-duration) ease-out forwards;
}



.piano-bars {
  display: flex;
  justify-content: space-between;
  position: absolute;
  /* Change from 'relative' to 'fixed' */
  top: 39.4dvw;
  /* Adjust this value to match the new height of the #piano container */
  width: 97.7vw;
  height: 8px;
  padding-left: 0.6vw;
  /* because bar width is smaller than white notes */
  z-index: 1;
}

.piano-bars-key {
  display: flex;
  justify-content: space-between;
  position: absolute;
  /* Change from 'relative' to 'fixed' */
  top: 8.76vw;
  /* Adjust this value to match the new height of the #piano container */
  width: 101vw;
  height: 8px;
  padding-left: 2.6vw;
  /* because bar width is smaller than white notes */
  margin: 0px -87.7vw 0px;
  z-index: 4;
}

.bar {
  border: 0px solid grey;
  /* ...other styles... */
}

.pc-display-note {
  /* bar */
  border: 0px solid grey;
  /* ...other styles... */
}

.active-pc-display {
  /* active-bar */
  border: 1px solid black;
  stroke: black;
  stroke-width: 2px;
}

.white {
  width: 1%;
  height: 10vw;
  /* background: rgb(90, 90, 90); */
  background: rgb(72, 72, 72);
  border: 0.5px solid rgb(146, 146, 146);
  position: relative;
  /* ...existing styles... */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.black {
  width: 1.2%;
  height: 7vw;
  background: linear-gradient(180deg, black, rgb(32, 32, 32), black, rgb(0, 0, 0));
  border: 1px solid rgb(50, 50, 50);
  position: absolute;
  z-index: 2;
  /* ...existing styles... */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}


.white .name-display {
  /* Position the note names at the bottom of white keys */
  top: -1.8dvw;
  left: -0.0dvw
    /* text-align: 3dvw; */
}

.black .name-display {
  /* Position the note names at the top of black keys */
  left: -0.15dvw;
  top: -3.2dvw;
}


.name-display {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  /* <--- added syles here and above */
  font-size: 1.5dvw;
  /* Adjust the font size as needed */
  /* font-family: 'Noto Music'; */
  font-family: 'Bravura Text';
  /* font-family: 'Trebuchet MS', sans-serif; */
  /* font-family: 'Arial Unicode MS', sans-serif; */
  font-weight: 100;
  /* Update with your desired font weight */
  text-shadow: 0.5px 0.5px 0.2dvw rgb(0, 0, 0), 1px -0.5px 0.2dvw rgb(0, 0, 0), -1px 0 rgb(0, 0, 0);
  /* stroke: black;
  stroke-width: 10; */
  color: #FFFFFF;
  /* Update with your desired font color */
  margin-bottom: 0.1dvw;
  /* Add a margin to create spacing between the note and the bottom */
}

.key-display {
  position: absolute;
  top: 3.3dvw;
  left: 1.10vw;
  bottom: 0;
  width: 100%;
  text-align: left;
  font-size: 1.2dvw;
  /* font-family: 'Noto Music'; */
  font-family: 'Bravura Text';
  /* font-family: 'Trebuchet MS', sans-serif; */
  /* font-family: 'Arial Unicode MS', sans-serif; */
  font-weight: 100;
  text-shadow: 0.5px 0.5px 0.2dvw rgb(0, 0, 0), 1px -0.5px 0.2dvw rgb(0, 0, 0), -1px 0 rgb(0, 0, 0);
  /* stroke: black;
  stroke-width: 10; */
  /* color: #acacac; */
  margin-bottom: 0dvw;
}


.mode-select-container {
  position: absolute;
  top: 3.1dvw;
  left: 2.9dvw;
}

/* font-size: 1.25dvw; /* Adjust the font size as needed */
/* font-family: 'Optima', sans-serif; Update with your desired font family */
/* font-weight: 600; Update with your desired font weight */
/* text-shadow: 0.5px 0.5px 0.2dvw rgb(0, 0, 0), 1px -0.5px 0.2dvw rgb(0, 0, 0), -1px -1px 0.2dvw rgb(0, 0, 0); */
/* color: #ffffff; Update with your desired font color */
/* margin-bottom: 0.1dvw; Add a margin to create spacing between the note and the bottom */

#chord-container {
  text-align: left;
  width: 20dvw;
  height: 32dvw;
  position: absolute;
  top: 2dvw;
  left: 14.5dvw;
  max-width: 20dvw;
}

.chord-display {
  /* background: #151515; */
  position: absolute;
  bottom: 0;
  /* width: 10dvw; */
  text-align: center;
  /* <--- added syles here and above */
  /* font-size: 2.2dvw; /* Adjust the font size as needed */
  /* font-family: 'Trebuchet MS', sans-serif; */
  /* font-family: 'Noto Music'; */
  font-family: 'Bravura Text';
  /* font-weight: 300; */
  /* Update with your desired font weight */
  /* text-shadow: 0.5px 0.5px 0.2dvw rgb(255, 255, 255), 1px -0.5px 0.2dvw rgb(255, 255, 255), -1px -1px 0rgb(255, 255, 255); */
  color: #bbb;
  /* Update with your desired font color */
  margin-bottom: 0.1dvw;
  /* Add a margin to create spacing between the note and the bottom */
  margin: 0.3dvw;
  padding: 1.3dvw;
  font-family: 'Bravura Text';
  word-spacing: 0.2dvw;
}

.chord-percent {
  font-size: 1.3dvw;
  font-family: 'Trebuchet MS', sans-serif;
  font-weight: 300;
  /* Update with your desired font weight */
  vertical-align: middle;
  text-align: right;
}

/* CHORD CHART STYLE BELOW */

.chord-chart-controls {
  top: -2.7dvw;
  position: absolute;
  left: 0dvw
}

.chord-chart {
  display: flex;
  flex-wrap: wrap;
  /* background-color: #bbb; */
  /* background-color: rgb(220, 220, 190); */
  background-color: rgb(0, 0, 0);
  border: solid 3px;
  margin-top: 0dvw;
  margin-left: 0dvw;
  /* background-image:
    linear-gradient(to left, */
  /* Greyscale option */
  /* rgb(255, 255, 255) 0%,
      rgb(202, 202, 202) 30%,
      rgb(194, 194, 194) 42%,
      rgb(78, 78, 78) 58%,
      rgb(33, 33, 33) 80%,
      rgb(0, 0, 0) 100%); */
  /* border: 0.2dvw; */
  border-radius: 1.8dvw;
  /* border-style: double; */
  /* border-color: grey; */
  color: #fff;
  padding: 0.3dvw 0.75dvw 1.5dvw 0.75dvw;
  position: absolute;
  /* bottom: 1.0dvw; */
  top: 5.5dvw;
  /* left: 22dvw; */
  right: 2dvw;
  /* max-width: 19dvw;
  min-width: 19dvw;
  min-height: 3dvw;
  max-height: 19dvw; */
  /* font-size: 1.2dvw; */
  font-family: 'Bravura Text';
  /* font-family: 'Arial Unicode MS'; */
  /* font-family: 'Trebuchet MS', sans-serif; */
  font-weight: 100;
  /* Update with your desired font weight */
  vertical-align: top;
  text-align: left;
  /* border: 3.3dvw; */
  border-color: #fff;
  /* padding-bottom: 1dvw; */
  /* line-height: 1.8dvw; */
  max-width: 36dvw;
  min-width: 36dvw;
  min-height: 3dvw;
  max-height: 200dvw;
  z-index: 5;

  /* padding: '0.5dvw', */
  margin-left: 2.5dvw;
  margin-right: 0dvw;
  margin-top: 0dvw;
  margin-bottom: 1.0dvw;
  border-style: solid;
  border-color: #334;
  border-spacing: 2dvw;
  border-radius: 1dvw;
  border-width: 0.15dvw
}

.chord {
  cursor: pointer;
  max-height: min-content;
  padding: 0.0dvw 0.25dvw;
}

.note {
  font-family: 'Bravura Text';
  cursor: pointer;
  max-height: min-content;
  padding: 0.0dvw 4.25dvw;
}

.selected-chord {
  /* line-height: 1.5dvw; */
  /* font-size: 1.4dvw; */
  background-color: rgb(60, 60, 60);
  position: relative;
  /* border: 0.2dvw solid #acacac; */
  border-radius: 0.8dvw;
  padding: 0dvw 0.25dvw;
  max-height: -moz-fit-content;
  max-height: fit-content;
  display: inline-block;
}

.chord-space {
  margin-right: 0.1dvw;
  /* padding: 0dvw 0dvw 0dvw 0.2dvw; */
}

.delete-entry,
.delete-disabled,
.copy-chart,
.add-separator,
.insert-blank-chord,
.chart-nav,
.play-song,
.history {
  margin-left: 0.0dvw;
  cursor: pointer;
  font-size: 1.0dvw;
  font-weight: 700;
  width: 2.2dvw;
  height: 2.2dvw;
  /* height: 2dvw; */
  position: absolute;
  /* Position it relative to the chord */
  bottom: -0.3dvw;
  /* transform: translateY(-2.3dvw) translateX(18.8dvw); */
  /* Center it vertically */
  padding: 0.00dvw 0.00dvw;
  background-color: #aaa;
  border-radius: 0.5dvw;
  /* line-height: 1.0dvw; */
}

.delete-entry {
  position: absolute;
  top: '3dvw';
  width: 2.2dvw;
  height: 2.2dvw;
  left: 13.5dvw;
  border: 0.3dvw double rgb(256, 0, 0);
}

.delete-disabled {
  position: absolute;
  left: 13.5dvw;
  width: 2.2dvw;
  height: 2.2dvw;
  background-color: #606060;
  border: 0.28dvw double rgb(3, 3, 3);
  color: rgb(0, 0, 0);
  pointer-events: none;
}

.copy-chart {
  left: 35.5dvw;
  width: 2.2dvw;
  height: 2.2dvw;
  border: 0.3dvw double rgb(0, 0, 255);
  font-size: 1.3dvw;
  line-height: 1dvw;
}

.add-separator {
  left: 11dvw;
  width: 2.2dvw;
  height: 2.2dvw;
  border: 0.3dvw double rgb(0, 0, 255);
  font-size: 1.1dvw;
  line-height: 1dvw;
}

.insert-blank-chord {
  left: 8.7dvw;
  width: 2.2dvw;
  height: 2.2dvw;
  border: 0.3dvw double rgb(0, 0, 255);
  font-size: 1.1dvw;
  line-height: 1dvw;
}

.chart-nav {
  width: 2.2dvw;
  height: 2.2dvw;
  border: 0.3dvw double rgb(0, 0, 0);
  font-size: 1dvw;
  ;
}

.chart-nav.backward {
  left: 4.1dvw;
}

.chart-nav.forward {
  left: 6.3dvw;
}

.play-song {
  left: -0.2dvw;
  width: 4.2dvw;
  height: 2.2dvw;
  border: 0.3dvw double rgb(0, 0, 0);
}

.history.history:active,
.chart-nav.chart-nav:active,
.delete-entry.delete-entry:active,
.copy-chart.copy-chart:active,
.insert-blank-chord.insert-blank-chord:active,
.add-separator.add-separator:active {
  width: 2.2dvw;
}


.play-song.play-song:active {
  width: 4.2dvw
}

.history {
  width: 2.2dvw;
  height: 2.2dvw;
  border: 0.3dvw double rgb(0, 0, 0);
  font-size: 1.4dvw;
  line-height: 1.5dvw;
}

.history.undo {
  left: 30.8vw;
}

.history.redo {
  left: 33dvw;
}

.history.redo-disabled {
  left: 33dvw;
  background-color: #606060;
  color: #000000;
  border: 0.28dvw double rgb(3, 3, 3);
  width: 2.2dvw;
  pointer-events: none;
}

.song-and-composer-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.song-title,
.song-title-input {
  flex-grow: 9;
  white-space: nowrap;
  text-align: center;
  cursor: pointer;
  font-style: normal;
  font-family: 'Bravura Text';
  font-weight: 700;
}

.song-title-input,
.composer-input,
.song-tempo-input {
  background-color: rgb(30, 30, 30);
  color: #FFFFFF;
  cursor: text;
  font-size: 1.3dvw;
}

.composer,
.composer-input {
  flex-grow: 1;
  white-space: nowrap;
  text-align: right;
  cursor: pointer;
  font-style: normal;
  font-family: 'Bravura Text';
  font-weight: 100;
}

.song-tempo,
.song-tempo-input {
  flex-grow: 3;
  white-space: nowrap;
  text-align: left;
  cursor: pointer;
  font-style: normal;
  font-family: 'Bravura Text';
  font-weight: 100;
  font-size: 2.2dvw;
  width: 0.1dvw;
}

.time-signature {
  /* background-color: rgb(0, 0, 0); */
  padding: 0.0dvw 0.0dvw;
  text-align: center;
  -webkit-appearance: none;
          appearance: none;
  cursor: pointer;
  color: #fff;
  font-style: normal;
  font-family: 'Bravura Text';
  /* font-size: 1.1dvw; */
  font-weight: 700;
  border-color: #ccc;
  border: 0;
}


.separator {
  /* background-color: rgb(38, 38, 38); */
  padding: 0.0dvw 0.0dvw;
  text-align: center;
  -webkit-appearance: none;
          appearance: none;
  cursor: pointer;
  color: #fff;
  position: relative;
  top: 0;
  /* font-style: normal; */
  font-family: 'Bravura Text';
  /* font-size: 1.1dvw; */
  /* font-weight: 700; */
  /* border-color: #ccc; */
  border: 0dvw;
  /* margin: 0dvw; */
  max-height: -moz-fit-content;
  max-height: fit-content
}

.selected-separator {
  /* line-height: 1.9dvw; */
  /* font-size: 1.1dvw; */
  background-color: rgb(60, 60, 60);
  color: #fff;
  position: relative;
  top: 0dvw;
  font-family: 'Bravura Text';
  /* font-style: normal; */
  /* position: relative; */
  border: 0.0dvw solid #acacac;
  border-radius: 1.7dvw;
  /* margin: 0dvw; */
  padding: 0.0dvw 0.0dvw;
  -webkit-appearance: none;
          appearance: none;
  text-align: center;
  max-height: -moz-fit-content;
  max-height: fit-content;
}



/* display: inline-block;
  background-color: #797979;
  border-radius: 1.7dvw;
  border: 0.28dvw double rgb(65, 65, 65);
  color: #000000;
  text-align: center;
  font-size: 0.9dvw;
  padding: 0.5dvw;
  width: 12dvw;
  transition: all 0.2s;
  cursor: pointer;
  margin: 0dvw; */

/* .chart-container {
  display: flex;
  align-items: center;
  max-width: 20dvw;
} */



.emo-display {
  /* background: #ffffff; */
  position: absolute;
  top: 2dvw;
  width: 22dvw;
  /* left: 48dvw; */
  /* right: 0dvw; */
  left: 37.5dvw;
  height: 32dvw;
  bottom: 0;
  /* width: 10dvw; */
  text-align: left;
  /* <--- added syles here and above */
  /* font-size: 2.2dvw; /* Adjust the font size as needed */
  /* font-family: 'Bravura Text'; */
  font-family: 'Trebuchet MS', sans-serif;
  font-weight: 100;
  /* Update with your desired font weight */
  /* font-style: italic; */
  text-shadow: 0.5px 0.5px 0.2dvw rgb(255, 255, 255), 1px -0.5px 0.2dvw rgb(255, 255, 255), -1px -1px 0rgb(255, 255, 255);
  color: #eaeaea;
  /* Update with your desired font color */
  /* margin-bottom: 0.1dvw; */
  /* Add a margin to create spacing between the note and the bottom */
  /* margin: 0.3dvw;
  padding: 1.3dvw; */
}


.emo-p-root {
  --activeColor: rgb(255, 255, 255), rgb(255, 255, 225);
  /* Replace with the desired color for root notes */
  color: var(--activeColor);
}

.emo-min-second {
  /* background-color: rgb(255, 0, 0); Replace with the desired color for min-second notes */
  --activeColor: rgb(190, 0, 0);
  color: var(--activeColor);
}

.emo-maj-second {
  --activeColor: rgb(255, 160, 0);
  /* Replace with the desired color for maj-second notes */
  color: var(--activeColor);
}

.emo-min-third {
  --activeColor: rgb(62, 62, 255);
  /* Replace with the desired color for min-third notes */
  color: var(--activeColor);
}

.emo-maj-third {
  --activeColor: rgb(255, 255, 0);
  /* Replace with the desired color for maj-third notes */
  color: var(--activeColor);
}

.emo-p-fourth {
  --activeColor: rgb(85, 210, 0);
  /* Replace with the desired color for p-fourth notes */
  color: var(--activeColor);
}

.emo-tritone {
  /* background-color: rgb(0, 255, 255); Replace with the desired color for tritone notes */
  --activeColor: rgb(0, 235, 235);
  color: var(--activeColor);
}

.emo-p-fifth {
  --activeColor: rgb(150, 140, 130);
  /* Replace with the desired color for p-fifth notes */
  color: var(--activeColor);
}

.emo-min-sixth {
  /* background-color: rgb(254, 0, 0); Replace with the desired color for min-sixth notes */
  --activeColor: rgb(170, 30, 170);
  color: var(--activeColor);
}

.emo-maj-sixth {
  --activeColor: rgb(255, 115, 0);
  /* Replace with the desired color for maj-sixth notes */
  color: var(--activeColor);
}

.emo-min-seventh {
  --activeColor: rgb(0, 150, 125);
  /* Replace with the desired color for min-seventh notes */
  color: var(--activeColor);
}

.emo-maj-seventh {
  --activeColor: rgb(255, 105, 175);
  /* Replace with the desired color for maj-seventh notes */
  color: var(--activeColor);
}


#keyboard-inputs {
  /* bottom: 0px; */
  left: 0.5dvw;
  text-align: left;
  /* text-align: left; */
  display: flex;
  /* justify-content: space-between; */
  height: 5dvw;
  top: 22dvw;
  /* Adjust this value to match the new height of the #piano container */
  width: 14dvw;
  position: absolute;
  z-index: 4;
}

#emo-inputs {
  /* bottom: 0px; */
  left: 64dvw;
  text-align: left;
  /* text-align: left; */
  display: flex;
  /* justify-content: space-between; */
  /* height: 5dvw; /* Adjust this value as needed */
  top: 54dvw;
  /* Adjust this value to match the new height of the #piano container */
  /* width: 100dvw; */
  position: absolute;
  z-index: 4;
  padding: 0.5dvw;
}

#emo-selects {
  top: 0dvw;
  left: -25dvw;
  position: absolute;
}


table {
  top: 0dvw;
  /* position: absolute; */
  /* font-family: 'Trebuchet MS', sans-serif; */
  font-size: 1.1dvw;
}

th,
td {
  padding: 0px;
  margin: 0px;
}

th {
  /* font-family: 'Trebuchet MS', sans-serif; */
  color: white;
  font-weight: 600;
  text-align: center;
  padding: 0px;
  margin: 0px;
  /* font-size: 1.1; */
}

td {
  /* font-family: 'Trebuchet MS', sans-serif; */
  /* font-family: 'Noto Music'; */
  /* font-family: 'Petaluma'; */
  /* font-family: 'Bravura Text'; */
  /* font-weight: 100; */
  /* font-style: italic; */
  padding: 0px;
  margin: 0px;
}

input {
  /* font-family: 'Noto Music'; */
  font-family: 'Trebuchet MS', sans-serif;
  font-style: italic;
  font-size: 1.1dvw;
  font-weight: 100;
  background-color: rgb(170, 170, 170);
  color: black;
  margin: 0px;
  height: 1dvw;
  width: 10dvw;
  padding: 0.1dvw;
}


.inputs {
  text-align: left;
  width: 35dvw;
  padding: 0.1dvw 0.1dvw;
  margin: 0.05dvw 0;
  box-sizing: border-box;
  /* background-color: gray; */
}

.chord-symbol {
  margin: 0.0dvw;
  padding: 0dvw;
  font-weight: 100;
  /* font-size: 2.8dvw; */
  color: white;
  font-family: 'Bravura Text';
}

.chord-symbol-poly {
  margin: 0.0dvw;
  padding: 0dvw;
  font-weight: 400;
  /* font-size: 2.4dvw; */
  color: rgb(170, 170, 170);
}

.chord-symbols {
  margin: 0;
  padding: 0dvw;
  /* font-size: 1.6dvw; */
  color: rgb(80, 80, 80);
  font-family: 'Bravura Text';
}

.title-text {
  margin: 0.0dvw;
  padding: 0.0dvw;
  top: 0;
  font-size: 1.2dvw;
  font-family: 'Trebuchet MS', sans-serif;
  /* font-style: italic; */
  /* text-decoration: overline; */
  color: rgb(220, 220, 220);
  text-align: left;
  text-shadow: #fff 0 0 0.1dvw;
}


select {
  background-color: #000000;
  /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif; */
  /* font-size: 0.9dvw; */
  color: rgb(145, 145, 145);
  top: 0dvw;
  position: absolute;
  border-radius: 0.4dvw;
  /* width: 12dvw; */
  /* height: 1dvw; */
  padding: 0.3dvw;
}


button {
  display: inline-block;
  background-color: #797979;
  border-radius: 1.7dvw;
  border: 0.28dvw double rgb(65, 65, 65);
  color: #000000;
  text-align: center;
  font-size: 0.9dvw;
  padding: 0.5dvw;
  /* width: 12dvw; */
  transition: all 0.2s;
  cursor: pointer;
  margin: 0dvw;
  position: relative;
  top: 0dvw;
}

button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.2s;
}

.button::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: -1;
  transform: scaleY(0.1);
  transition: all 0.1s;
}

button span:after {
  /* content: '\21Ba'; /* '\00bb'; */
  /* content: '\00bb'; */
  position: absolute;
  opacity: 0;
  right: -20px;
  transition: 0.0s;
}

.button-disabled {
  background-color: #4b4b4b;
  color: #000000;
  border: 0.28dvw double rgb(3, 3, 3);
  color: rgb(0, 0, 0);
  width: 12dvw;
  pointer-events: none;
}

button:active {
  background-color: #4b4b4b;
  color: #000000;
  border: 0.32dvw double rgb(3, 3, 3);
  color: rgb(0, 0, 0);
  width: 11.92dvw;
  /* text-shadow: 0 0 10px #000, 0 0 10px #000, 0 0 22px #ccc, 0 0 42px #bbb,0 0 62px #aaa; */
}

button:active span {
  padding-left: 0.0dvw;
}

button:hover span:after {
  opacity: 1;
  right: 0;
}


/* .active {
  /* background-color: rgb(0, 255, 255); /* Replace with the desired color for active notes */


.p-root {
  --activeColor: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 225));
  /* Replace with the desired color for root notes */
  background-image: var(--activeColor);
}

.min-second {
  /* --activeColor: linear-gradient(180deg, rgb(0, 235, 235) 0%, rgb(225, 0, 0) 40%, rgb(190, 0, 0) 100%); */
  --activeColor: linear-gradient(180deg, rgb(225, 0, 0) 0%, rgb(225, 0, 0) 40%, rgb(225, 0, 0) 100%);
  background-image: var(--activeColor);
}

.maj-second {
  --activeColor: linear-gradient(rgb(255, 160, 0), rgb(255, 160, 0));
  /* Replace with the desired color for maj-second notes */
  background-image: var(--activeColor);
}

.min-third {
  --activeColor: linear-gradient(rgb(62, 62, 255), rgb(62, 62, 255));
  /* Replace with the desired color for min-third notes */
  background-image: var(--activeColor);
}

.maj-third {
  --activeColor: linear-gradient(rgb(255, 255, 0), rgb(255, 255, 0));
  /* Replace with the desired color for maj-third notes */
  background-image: var(--activeColor);
}

.p-fourth {
  --activeColor: linear-gradient(250deg, rgb(85, 210, 0), rgb(85, 210, 0));
  /* Replace with the desired color for p-fourth notes */
  background-image: var(--activeColor);
}

.tritone {
  /* background-color: rgb(0, 255, 255); Replace with the desired color for tritone notes */
  /* --activeColor: linear-gradient(180deg, rgb(225, 0, 0) 3%, rgb(0, 235, 235) 37%, rgb(1, 235, 235) 100%); */
  --activeColor: linear-gradient(180deg, rgb(0, 235, 235) 3%, rgb(0, 235, 235) 37%, rgb(0, 235, 235) 100%);
  background-image: var(--activeColor);
}

.p-fifth {
  --activeColor: linear-gradient(rgb(150, 140, 130), rgb(150, 140, 130));
  /* Replace with the desired color for p-fifth notes */
  background-image: var(--activeColor);
}

.min-sixth {
  /* background-color: rgb(254, 0, 0); Replace with the desired color for min-sixth notes */
  /* --activeColor: linear-gradient(0deg, rgb(170, 30, 170), rgb(170, 30, 170) 70%, rgb(0, 235, 235) 90%); */
  --activeColor: linear-gradient(0deg, rgb(170, 30, 170), rgb(170, 30, 170) 70%, rgb(170, 30, 170) 90%);
  background-image: var(--activeColor);
}

.maj-sixth {
  --activeColor: linear-gradient(rgb(255, 115, 0), rgb(255, 115, 0));
  /* Replace with the desired color for maj-sixth notes */
  background-image: var(--activeColor);
}

.min-seventh {
  --activeColor: linear-gradient(rgb(0, 150, 125), rgb(0, 150, 125));
  /* Replace with the desired color for min-seventh notes */
  background-image: var(--activeColor);
}

.maj-seventh {
  --activeColor: linear-gradient(rgb(255, 105, 175), rgb(255, 105, 175));
  /* Replace with the desired color for maj-seventh notes */
  background-image: var(--activeColor);
}

/* .image-container img {
  position: absolute;
  width: 100%;
  height: 100%;
} */


/* Pie Chart Below */

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  /* 50% { transform: scale(1.1); } */
  100% {
    transform: scale(1.05);
  }
}

@keyframes pulse-slice {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.08);
  }
}

@keyframes pulse-key {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.07);
  }
}


.path {
  transition: fill 0.3s ease;
  opacity: 0.8;
  /* transform-origin: center; */
  /* fill: #ccc; /* Default color */
}

.path.active {
  animation: pulse 1s infinite;
  /* fill: #f00; /* Active color */
}

svg {
  /* background-color: #300; /* SVG background color */
  width: 100%;
  height: 37dvw;
  /* opacity: 0.4; */
}



.p-root,
.min-second,
.maj-second,
.min-third,
.maj-third,
.p-fourth,
.tritone,
.p-fifth,
.min-sixth,
.maj-sixth,
.min-seventh,
.maj-seventh {
  fill: #ccc;
  /* Default color for the pie slices */
}

.active-slice {
  animation: pulse-slice 0.3s forwards;
  transform-origin: 0dvw 0dvw;
  stroke: black;
  stroke-width: 0.4;
  stroke-opacity: 1;
  font-weight: 700
    /* opacity: 1; */
    /* transition: fill 0.9s ease; */
    /* fill: #f00; /* Active color for the pie slices */
}


.pie-container {
  width: 37dvw;
  /* adjust this to change the size of the image */
  height: 32dvw;
  padding-bottom: 0dvw;
  /* this determines the aspect ratio */
  position: absolute;
  /* this allows the image to be positioned absolutely within the container */
  top: 3.3dvw;
  right: 0dvw;
  /* transform: translate(-0dvh, -2dvh); */
}



#pie-center-container {
  /* Removed, not needed, pie chart handles */

  display: flex;
  justify-content: space-between;
  position: fixed;
  /* Change from 'relative' to 'fixed' */
  top: 18.3dvw;
  /* Adjust this value to match the new height of the #piano container */
  right: 12dvw;
  width: 18vw;
  height: 4dvw;
  padding-left: 0px;
  /* because bar width is smaller than white notes */
  font-family: Trebuchet MS;
  font-weight: 100;
  text-align: center;
  text-anchor: middle;
  color: white;
  z-index: 5;
}


.pie-chord-symbol {
  /* width: 10dvw;  adjust this to change the size of the image */
  /* height: 32dvw; /* 33dvw; */
  /* font-family: 'Noto Music'; */
  font-family: 'Bravura Text';
  /* font-family: Trebuchet MS; */
  margin: 0.0dvw;
  padding: 0dvw;
  font-weight: 100;
  /* font-size: 2.8dvw; */
  color: black;
  text-anchor: middle;
  text-align: center;
}


.pie-chart {
  width: 37.5dvw;
  /* adjust this to change the size of the image */
  height: 32.5dvw;
  /* 33dvw; */
  padding-bottom: 0dvh;
  /* this determines the aspect ratio */
  position: absolute;
  /* this allows the image to be positioned absolutely within the container */
  top: 3dvw;
  right: 1.7dvw;
  /* left: 37.5dvw; */
  background-image:
    linear-gradient(to bottom,
      /* Blue and Yellow tint color option */
      rgb(255, 255, 255) 0%,
      rgb(255, 254, 214) 15%,
      rgb(243, 197, 157) 45.5%,
      rgb(107, 117, 142) 48.5%,
      rgb(39, 49, 91) 69%,
      rgb(0, 0, 0) 100%);
  /* border: 0.2dvw;
  border-radius: 1.8dvw;
  border-style: double;
  border-color: grey; */
  border: solid #334;
  border-radius: 1dvw;
  border-width: 0.05dvw;
  border-color: #fff;
  border-spacing: '2dvw';
  box-shadow: 0 0 0.5dvw 0.1dvw #aaa;

  /* background-image: linear-gradient(to left, rgb(255, 255, 255) 0%, rgb(255, 239, 166) 20%, rgb(255, 162, 96) 48%, rgb(53, 83, 88) 52%, rgb(6, 15, 83) 80%,  rgb(0, 0, 0) 100%); */
}



/* Blue and Yellow tint color option */
/* rgb(255, 255, 255) 0%,
  rgb(255, 254, 214) 15%,
  rgb(243, 197, 157) 48.5%,
  rgb(107, 117, 142) 51.5%,
  rgb(39, 49, 91) 69%,
  rgb(0, 0, 0) 100%); */


/* Blue and Yellow tint color option 2 */
/* rgb(255, 255, 255) 0%,
  rgb(229, 231, 162) 30%,
  rgb(235, 201, 150) 40%,
  rgb(93, 96, 121) 60%,
  rgb(28, 33, 80) 80%,
  rgb(0, 2, 28) 100%); */

/* Greyscale option */
/* rgb(255, 255, 255) 0%,
rgb(220, 220, 220) 20%,
rgb(151, 151, 151) 49%,
rgb(111, 111, 111) 51%,
rgb(93, 93, 93) 60%,
rgb(0, 0, 0) 100%); */


/* Greyscale option 2 */
/* rgb(255, 255, 255) 0%,
rgb(202, 202, 202) 30%,
rgb(194, 194, 194) 42%,
rgb(78, 78, 78) 58%,
rgb(33, 33, 33) 80%,
rgb(0, 0, 0) 100%); */

/* .slider-parent{
  position:relative;
}
.buble{
  position:absolute;
} */

.slider-parent {
  display: flex;
  align-items: center;
  justify-content: left;
  color: #fff;
  height: 3dvw;
  left: 3dvw;
}

input[type="range"] {
  transform: rotate(-90deg);
  /* Rotates the slider */
  cursor: pointer;
  outline: none;
  width: 9dvw;
  top: 0dvw;
  overflow: hidden
    /* -webkit-appearance: none;  */
}

.buble {
  height: 3dvw;
  position: absolute;
  font-size: 1.4dvw;
  /* color: #000; */
}

/* The following makes no text selectable on the page */
html, body, .App {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
